





























































































































import { Vue, Component, Prop } from "vue-property-decorator";
import VerifyEmail from "../../../common-app/components/VerifyEmail.vue";

@Component({
  components: {
    VerifyEmail,
  },
})
export default class HospitalProfileWidget extends Vue {
  public verifyEmailModal: boolean = false;
  public isShow: Boolean = false;
  imgSrc: any = null;
  cropImg: any = "";
  fileName: string = "";

  $refs: {
    cropper: HTMLFormElement;
  };

  openVerifyEmailModal() {
    // console.log(this.verifyEmailModal);
    this.verifyEmailModal = true;
  }

  verifyEmailClosed() {
    this.verifyEmailModal = false;
  }
}

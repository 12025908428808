














































import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DiagnosticSideNav extends Vue {}

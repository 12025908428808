











































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import UniversalService from "../service/UniversalService";
import AuthUser from "../../global-config/AuthUser";
import AlertService from "../service/AlertService";
@Component({})
export default class VerifyEmail extends Vue {
  public isShow: Boolean = true;
  public EmailText = "";
  public submitted: boolean = false;
  created() {
    const dict = {
      custom: {
        email: {
          required: "The email is required",
          email: "The email address must be a valid email.",
        },
      },
    };
    this.$validator.localize("en", dict);
    if (AuthUser.IsEmailLogin()) {
      this.isShow = false;
    }
  }
  get email() {
    return this.EmailText;
  }
  set email(email) {
    this.EmailText = email;
  }
  EmailVerifyCodeSend() {
    this.submitted = true;
    var data = {
      Email: this.EmailText,
      UserId: AuthUser.getId(),
    };
    UniversalService.verifyEmail(data).then((e) => {
      //alert(e.data.message);
      if (e.data.Status == true) {
        this.isShow = false;
      }
      AlertService.sendMessage({ status: e.data.status, message: e.data.message });
      this.submitted = false;
    });
  }
  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
}

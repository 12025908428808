













































































import { Vue, Component, Prop } from "vue-property-decorator";
import HospitalProfileWidget from "./components/HospiitalProfileWidget.vue";
import DiagnosticSideNav from "./components/DiagnosticSidenav.vue";
@Component({
  components: {
    HospitalProfileWidget,
    DiagnosticSideNav,
  },
})
export default class Dashboard extends Vue {}
